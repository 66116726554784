var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Table',{attrs:{"headers":_vm.header,"items":_vm.files,"search":_vm.search,"loading":_vm.loading,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.order_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateStr(item.order_date))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTimeStr24(item.created_at))+" ")]}},{key:"item.type_id",fn:function(ref){
var item = ref.item;
return [_c('Autocomplete',{attrs:{"items":_vm.fileTypesArr,"item-value":"id","item-text":"text","label":_vm.fileTypeLabel,"single-line":"","hide-details":"","solo":"","dense":"","flat":""},on:{"change":function($event){return _vm.onChangeType(item, $event)}},model:{value:(item.type_id),callback:function ($$v) {_vm.$set(item, "type_id", $$v)},expression:"item.type_id"}})]}},{key:"item.mime",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.mimeIcon(item.mime))?_c('v-icon',_vm._g({domProps:{"textContent":_vm._s(_vm.mimeIcon(item.mime))}},on)):_c('span',{domProps:{"textContent":_vm._s(item.mime)}})]}}],null,true)},[_vm._v(" "+_vm._s(item.mime)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',{attrs:{"href":item.link + '/direct',"target":"_blank"}},[_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"success"}},on),[_vm._v("mdi-file-download-outline")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('table.files.downloadFileTooltip'))}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteFile(item)}}},on),[_vm._v("mdi-file-remove-outline")])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('table.files.deleteFileTooltip'))}})])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }